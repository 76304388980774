<template>
    <button v-if="!noButton" v-target:o365_shared-with-person v-bind="$attrs" >{{$t('Share with')}}</button>
    <OModal ref="modal" :name="noButton ? null : 'o365_shared-with-person'" @shown="onShown"
        @show="onShow" @hide="onHide">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title fs-5">{{ $t('Share with Persons') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" stlye="max-height: 600px">
                    <ODataGrid :dataObject = "dsSharedWith" hideGridMenu>
                        <OColumn name="PersonName" editable width="400px">
                            <template #editor="{row}">
                                <OPersonsLookup v-model="row.PersonName" :bind="(sel) => { row.Person_ID = sel.ID; row.PersonName = sel.Name }" ></OPersonsLookup>
                            </template>
                        </OColumn>
                    </ODataGrid> 
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup lang="ts">
import type { FilterItem } from 'o365-filterobject';
import { OModal } from 'o365-ui-components';
import { OPersonsLookup } from 'o365-system-lookups';
import { filterStore } from 'o365-filterobject';
import { ref } from 'vue';

const props = defineProps<{
    filterItem: FilterItem,
    noButton?: boolean
}>();

const emit = defineEmits<{
    (e: 'show'):void
    (e: 'shown'):void
    (e: 'hide'):void
}>();

const modal = ref<OModal | null>(null);
const dsSharedWith = filterStore.getSharedWithDO();

function onShow() {
    emit('show');
}

function onShown(e) {
    if (props.filterItem.id || props.filterItem.ID) {
        dsSharedWith.fields.Filter_ID.defaultValue = props.filterItem.id || props.filterItem.ID;
        dsSharedWith.recordSource.whereClause = `[Filter_ID] = '${props.filterItem.id ?? props.filterItem.ID}'`;
        dsSharedWith.load();
    }
    emit('shown');
}

function onHide() {
    dsSharedWith.storage.clearItems();
    dsSharedWith.dynamicLoading.dataLoaded([]);
    emit('hide');
}

function show() {
    if (modal.value?.show) {
        modal.value.show();
    }
}

function hide() {
    if (modal.value?.hide) {
        modal.value.hide();
    }
}

defineExpose({ modal, show, hide });

</script>